import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { ContainerRowComponent } from "./container-row.component";
import { GridColDirective } from "./grid-col.component";
import { NgClass } from "@angular/common";
import { ResponsiveStyleObjectType } from "@/types/responsive-style-object.type";

@Component({
  selector: "cup-full-layout",
  standalone: true,
  imports: [ContainerRowComponent, NgClass, GridColDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div cupContainerRow [ngClass]="containerClasses()">
      <div cupGridCol [class]="colClass()" [config]="defaultConfig">
        <ng-content />
      </div>
    </div>
  `,
})
export class FullLayoutComponent {
  colConfig = input<ResponsiveStyleObjectType>();
  colClass = input<string | string[]>();
  containerClasses = input<string | string[]>();

  get defaultConfig() {
    return {
      xs: 12,
      ...this.colConfig(),
    };
  }
}
