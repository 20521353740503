import { ChangeDetectionStrategy, Component, input } from "@angular/core";

@Component({
  selector: "div[cupContainerRow], section[cupContainerRow]",
  standalone: true,
  template: `
    <div class="row" [class]="rowClass()">
      <ng-content />
    </div>
  `,
  host: {
    "[class]": "hostClass",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerRowComponent {
  readonly hostClass = "container-xxl";
  rowClass = input<string>();
}
