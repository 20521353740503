import { Directive, input } from "@angular/core";
import { ResponsiveStyleObjectType } from "@/types/responsive-style-object.type";

@Directive({
  selector: "div[cupGridCol]",
  standalone: true,
  host: {
    "[class]": "hostClass",
  },
})
export class GridColDirective {
  config = input.required<ResponsiveStyleObjectType>();

  get hostClass() {
    return Object.entries(this.config()).reduce<string[]>(
      (acc, [key, value]) => [
        ...acc,
        key === "xs" ? `col-${value}` : `col-${key}-${value}`,
      ],
      []
    );
  }
}
